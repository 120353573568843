import useSWR, { mutate } from 'swr';
import { Account } from '@Types/account/Account';
import { Address } from '@Types/account/Address';
import {
  REMEMBER_ME,
  EXPLICIT_USER_LOGIN_STATUS,
  LOGGED_IN,
  LOGGED_OUT,
  FETCH_SIGNEDIN_USER_DETAIL,
} from 'helpers/constants/localStorage';
import { revalidateOptions } from 'frontastic';
import { fetchApiHub, ResponseError } from 'frontastic/lib/fetch-api-hub';
import { number, object, string } from 'yup';
export interface GetAccountResult {
  loggedIn: boolean;
  account?: any;
  error?: ResponseError;
  accountLoading?: boolean;
  resetDefaultStore?: boolean;
}

export interface UpdateAccount {
  firstName?: string;
  lastName?: string;
  email?: string;
  profilePhone?: number;
  salutation?: string;
  birthdayYear?: number;
  birthdayMonth?: number;
  birthdayDay?: number;
}

export interface RegisterAccount extends UpdateAccount {
  email: string;
  password: string;
  billingAddress?: Address;
  shippingAddress?: Address;
}

export const getAccount = (): GetAccountResult => {
  const result = useSWR<Account | GetAccountResult>('/action/account/getAccount', fetchApiHub, revalidateOptions);

  const account = (result.data as GetAccountResult)?.account || (result.data as Account);

  if (account?.accountId && account?.confirmed)
    return { account, loggedIn: true, accountLoading: result?.isValidating };

  return {
    loggedIn: false,
    account: undefined,
    error: result.error,
    accountLoading: result?.isValidating,
  };
};

const handleUserDetailsValidation = (previous_User, current_User) => {
  if (previous_User !== undefined && previous_User !== null && previous_User !== current_User) {
    mutate('/action/account/getCreditCards');
    mutate('/action/wishlist/getSaveForLater');
    mutate('/action/wishlist/getWishlist');
  }
};

export const login = async (
  email: string,
  password: string,
  isRememberMe?: boolean,
  isLoggedInWithCreateAccount?: any,
): Promise<Account> => {
  const payload = {
    email,
    password,
    isRememberMe,
  };
  if (isRememberMe) window.localStorage.setItem(REMEMBER_ME, '1');

  const res = await fetchApiHub('/action/account/login', { method: 'POST' }, payload);
  if (!res.errorCode) {
    window.localStorage.setItem(EXPLICIT_USER_LOGIN_STATUS, LOGGED_IN);
    const previous_User = window.localStorage.getItem(FETCH_SIGNEDIN_USER_DETAIL);
    window.localStorage.setItem(FETCH_SIGNEDIN_USER_DETAIL, email);
    if (isLoggedInWithCreateAccount) {
      isLoggedInWithCreateAccount.current = true;
    }
    if (res?.accountId) {
      await mutate('/action/account/getAccount', res);
      await mutate('/action/cart/getCart');
      sessionStorage?.removeItem('isSessionExpired');
      const current_User = window.localStorage.getItem(FETCH_SIGNEDIN_USER_DETAIL);
      handleUserDetailsValidation(previous_User, current_User);
    }
  }
  return res;
};

export const logout = async (accountId: string) => {
  const payload = {
    key: accountId,
  };
  window.localStorage.removeItem(REMEMBER_ME);
  window.localStorage.removeItem(FETCH_SIGNEDIN_USER_DETAIL);
  const res = await fetchApiHub('/action/account/logout', { method: 'POST' }, payload);
  if (!res.errorCode) {
    window.localStorage.setItem(EXPLICIT_USER_LOGIN_STATUS, LOGGED_OUT);
  }
  await mutate('/action/account/getAccount', res);
  await mutate('/action/cart/getCart');
  mutate('/action/account/getCreditCards', undefined, false);
  mutate('/action/wishlist/getSaveForLater', undefined, false);
  mutate('/action/wishlist/getWishlist', undefined, false);
  return res;
};

export const register = async (account: RegisterAccount): Promise<Account> => {
  const response = await fetchApiHub('/action/account/register', { method: 'POST' }, account);
  return response;
};

export const confirm = async (token: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, { token });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const requestConfirmationEmail = async (email: string, password: string): Promise<void> => {
  const payload = {
    email,
    password,
  };
  const res = await fetchApiHub('/action/account/requestConfirmationEmail', { method: 'POST' }, payload);
  return res;
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<Account> => {
  return await fetchApiHub('/action/account/password', { method: 'POST' }, { oldPassword, newPassword });
};

export const requestPasswordReset = async (
  email: string,
  cidNumber: string,
  isEmail?: boolean,
  isSms?: boolean,
): Promise<void> => {
  const payload = {
    cidNumber,
    email,
    isEmail,
    isSms,
  };

  return await fetchApiHub('/action/account/requestResetPassword', { method: 'POST' }, payload);
};

export const resetPassword = async (token: string, newPassword: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/resetPassword', { method: 'POST' }, { token, newPassword });
  await mutate('/action/account/getAccount', res);
  return res;
};

export const update = async (account: UpdateAccount): Promise<Account> => {
  const res = await fetchApiHub('/action/account/update', { method: 'POST' }, account);
  if (res.errorCode !== 500) {
    await mutate('/action/account/getAccount', res);
  }
  return res;
};

export const addAddress = async (address: Omit<Address, 'addressId'>): Promise<Account> => {
  const res = await fetchApiHub('/action/account/addAddress', { method: 'POST' }, address);
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const updateAddress = async (address: Address): Promise<Account> => {
  const res = await fetchApiHub('/action/account/updateAddress', { method: 'POST' }, address);
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const removeAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/removeAddress', { method: 'POST' }, { addressId });
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const setDefaultBillingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultBillingAddress', { method: 'POST' }, { addressId });
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const setDefaultShippingAddress = async (addressId: string): Promise<Account> => {
  const res = await fetchApiHub('/action/account/setDefaultShippingAddress', { method: 'POST' }, { addressId });
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const defaultStoreAddress = async (addressKey: string): Promise<any> => {
  const payload = {
    key: addressKey,
  };
  const res = await fetchApiHub('/action/account/setDefaultStore', { method: 'POST' }, payload);
  await mutate('/action/account/getAccount', res);
  return res;
};

export const listingStores = async (addressId: string): Promise<any> => {
  const payload = {
    productId: addressId,
  };
  const res = await fetchApiHub('/action/channel/getAllChannels', { method: 'POST' }, payload);
  return res;
};

export const getChannelsSearch = async (searchInput: string, productId: number): Promise<any> => {
  const payload = productId
    ? {
        searchTerm: searchInput,
        productId: productId,
      }
    : {
        searchTerm: searchInput,
      };
  const res = await fetchApiHub('/action/channel/getChannelsBySearch', { method: 'POST' }, payload);
  return res;
};

export const updateCustomerPreferences = async (
  subsType: string,
  subsValue: string,
  subsChannel: string,
  subsChannelValue: string,
): Promise<Account> => {
  const payload = {
    subscriptionType: subsType,
    subscriptionValue: subsValue,
    subscriptionChannel: subsChannel,
    subscriptionChannelValue: subsChannelValue,
  };
  const res = await fetchApiHub('/action/account/updateCustomerPreference', { method: 'POST' }, payload);
  if (res?.accountId) {
    mutate('/action/account/getAccount', res, { revalidate: false });
  }
  return res;
};

export const updateCompareOTP = async (otpCode?: string, token?: string, otpExpDate?: string): Promise<Account> => {
  const payload = {
    otpCode: otpCode,
    token: token,
    otpExpDate: otpExpDate,
  };
  const res = await fetchApiHub('/action/account/compareOTPwithToken', { method: 'POST' }, payload);
  return res;
};

export const requestResetUsername = async (
  email: string,
  cidNumber: string,
  isEmail?: boolean,
  isSms?: boolean,
): Promise<Account> => {
  const payload = {
    email: email,
    cidNumber: cidNumber,
    isEmail: isEmail,
    isSms: isSms,
  };
  const res = await fetchApiHub('/action/account/requestResetUserName', { method: 'POST' }, payload);
  return res;
};

export const compareUserNameOTPWithToken = async (
  otpCode: string,
  token: string,
  email: string,
  otpExpDate?: string,
): Promise<Account> => {
  const payload = {
    otpCode: otpCode,
    token: token,
    email: email,
    otpExpDate: otpExpDate,
  };
  const res = await fetchApiHub('/action/account/fetchUsername', { method: 'POST' }, payload);

  return res;
};

export const getBranchesOfService = async (): Promise<Account> => {
  const res = await fetchApiHub('/action/customObjects/getBranchesOfService');
  return res;
};
export const getCcdValidationCheck = async (data): Promise<Account> => {
  const payload = {
    firstName: data.firstName,
    lastName: data.lastName,
    ssn: data.ssn,
    dob: `${data.dobYear}${data.dobMonth}${data.dobDay}`,
    email: data.email,
    branchOfService: data.branchService,
  };

  const res = await fetchApiHub('/action/account/getCcdValidationCheck', { method: 'POST' }, payload);
  return res;
};

export const verifySSN = async (data: any): Promise<any> => {
  const payload: any = {
    ssn: data.ssn,
    dob: `${data.dobYear}${data.dobMonth}${data.dobDay}`,
  };
  if (data.lastName !== undefined && data.lastName !== '') {
    payload.lastName = data.lastName;
  }
  const res = await fetchApiHub('/action/account/getCcdSSNCheck', { method: 'POST' }, payload);
  return res;
};
export const createAccount = async (data): Promise<any> => {
  const payload: any = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    password: data.password,
    dateOfBirth: `${data.dobYear}-${data.dobMonth}-${data.dobDay}`,
    key: data.userName,
    customerNumber: data.userName,
    branchOfService: data.branchOfService,
    branchOfServiceDesc: data.branchOfServiceDesc,
    hearAboutUs: data.hearAbout,
    profilePhone: data.phoneNumber,
    cid: parseInt(data.ccdApiResponse.CID),
    statusCode: data.ccdApiResponse.StatusCode,
    statusDesc: data.ccdApiResponse.StatusDesc,
    rank: data.ccdApiResponse.Rank,
  };
  const res = await fetchApiHub('/action/account/register', { method: 'POST' }, payload);
  return res;
};
export const confirmAccount = async (data): Promise<any> => {
  const payload: any = {
    token: data?.confirmationToken?.token,
  };

  const res = await fetchApiHub('/action/account/confirm', { method: 'POST' }, payload);
  return res;
};
export const getOrderDetail = async (orderId: string): Promise<any> => {
  const payload = {
    orderId,
  };
  const res = await fetchApiHub('/action/cart/getMaoOrder', { method: 'POST' }, payload);
  return res;
};
export const accountChangePassword = async (data): Promise<any> => {
  const payload: any = {
    oldPassword: data?.oldPassword,
    newPassword: data?.newPassword,
  };

  const res = await fetchApiHub('/action/account/changePassword', { method: 'POST' }, payload);
  return res;
};
export const getNewCreditCards = async (): Promise<any> => {
  const res = await fetchApiHub('/action/account/getCreditCards', { method: 'GET' });
  return res;
};
export const deleteCreditCards = async (data): Promise<any> => {
  const payload: any = {
    token: data?.token,
    cardType: data?.cardtype,
  };

  const res = await fetchApiHub('/action/account/deleteCreditCard', { method: 'POST' }, payload);
  await mutate('/action/account/getCreditCards');
  return res;
};
export const addCreditCards = async (payload): Promise<any> => {
  const res = await fetchApiHub('/action/account/addCreditCard', { method: 'POST' }, payload);
  mutate('/action/account/getCreditCards');
  return res;
};
export const updateCreditCards = async (payload): Promise<any> => {
  const res = await fetchApiHub('/action/account/updateCreditCard', { method: 'POST' }, payload);
  await mutate('/action/account/getCreditCards');
  return res;
};

export const getCreditCards = (loggedIn) => {
  const { data, isValidating } = useSWR(
    loggedIn ? '/action/account/getCreditCards' : null,
    fetchApiHub,
    revalidateOptions,
  );
  return {
    getCreditCards: data,
    getCreditCardsLoading: isValidating,
  };
};

export const getSubscriptionTemplateOrders = async (): Promise<any> => {
  const res = await fetchApiHub('/action/account/getSubscriptionTemplateOrders', { method: 'GET' });
  return res;
};
export const getSubscriptionOrders = async (): Promise<any> => {
  const res = await fetchApiHub('/action/account/getSubscriptionOrders', { method: 'GET' });
  return res;
};

export const getCMSTemplate = async (data): Promise<any> => {
  const queryParams = new URLSearchParams({
    assetPath: data.assetPath,
    login: data.loginCheck,
  });

  const url = `/action/account/fetchLoginTemplate?${queryParams}`;

  const res = await fetchApiHub(url, { method: 'GET' });
  return res;
};

export const getDomainURL = async (): Promise<any> => {
  const res = await fetchApiHub('/action/project/getAllData', { method: 'GET' });
  return res;
};

export const isEmailExist = async (data): Promise<any> => {
  const payload: any = {
    email: data,
  };
  const res = await fetchApiHub('/action/account/isEmailExist', { method: 'POST' }, payload);
  return res;
};

export const cancelSubscriptionOrder = async (orderId: string): Promise<any> => {
  const payload = {
    orderId,
  };

  const res = await fetchApiHub('/action/account/cancelSubscriptionOrder', { method: 'POST' }, payload);
  return res;
};
export const skipNextSubscriptionOrder = async (orderId: string): Promise<any> => {
  const payload = {
    orderId,
  };

  const res = await fetchApiHub('/action/account/skipNextSubscriptionOrder', { method: 'POST' }, payload);
  return res;
};

export const validateAddress = async (data): Promise<any> => {
  const payload: any = data;
  const res = await fetchApiHub('/action/address/validateAddress', { method: 'POST' }, payload);
  return res;
};

export const defaultStoreByGeo = async (data): Promise<any> => {
  const payload: any = {
    lat: data?.latitude,
    lng: data?.longitude,
  };
  const res = await fetchApiHub('/action/account/setDefaultStoreByGeo', { method: 'POST' }, payload);
  await mutate('/action/account/getAccount');
  return res;
};

export const updateSubscriptionShippingAddress = async (payload: {
  orderId: string;
  shippingAddress?: {
    firstName?: string;
    lastName?: string;
    streetName: string;
    streetNumber: string;
    postalCode: string;
    city: string;
    country: string;
    state: string;
    addressId: string;
  };
  shippingMethodId: string;
}) => {
  const res = await fetchApiHub('/action/account/updateShippingAddressSO', { method: 'POST' }, payload);
  return res;
};

export const updateSubscriptionBillingAddress = async (payload: {
  orderId: string;
  billingAddress: {
    addressId: string;
  };
}) => {
  const res = await fetchApiHub('/action/account/updateBillingAddressSO', { method: 'POST' }, payload);
  return res;
};
export const updateSubscriptionPayment = async (
  orderId: string,
  creditCardToken: string,
  paymentMethod: string,
  cardType: string,
  billingAddress: Address,
  expDate: string,
): Promise<any> => {
  const payload = {
    orderId,
    creditCardToken,
    paymentMethod,
    cardType,
    billingAddress,
    expDate,
  };
  const res = await fetchApiHub('/action/account/updatePaymentSO', { method: 'POST' }, payload);
  return res;
};

export const updateSubscriptionFrequency = async (orderId: string, subscriptionFrequency: string): Promise<any> => {
  const payload = {
    orderId,
    subscriptionFrequency,
  };
  const res = await fetchApiHub('/action/account/updateFrequencySO', { method: 'POST' }, payload);
  return res;
};

export const returnItem = async (
  payload = {
    orderId: string,
    orderLineId: string,
    itemId: string,
    quantity: number,
    returnReason: string,
    price: object,
    shippingMethodId: string,
    channelKey: string,
    firstName: string,
    lastName: string,
  },
): Promise<any> => {
  const res = await fetchApiHub('/action/cart/returnItem', { method: 'POST' }, payload);
  return res;
};

export const itemReturnLabel = async (data): Promise<any> => {
  const payload = {
    retunLabelId: data,
  };
  const res = await fetchApiHub('/action/cart/getReturnLabel', { method: 'POST' }, payload);
  return res;
};

export const sendWishlistTemplate = async (data, email): Promise<any> => {
  const payload = {
    toEmail: email.email,
    senderName: data.yourName,
    message: data.message,
    senderEmail: data.yourEmail,
  };
  const res = await fetchApiHub('/action/account/sendWishlistTemplate', { method: 'POST' }, payload);
  return res;
};

export const validateSweepstake = async (data): Promise<any> => {
  const payload = {
    sweepId: data,
  };
  const res = await fetchApiHub('/action/sweepstakes/validateSweepstakes', { method: 'POST' }, payload);
  return res;
};

export const submitSweepstake = async (
  payload = {
    sweepstakesForm: {
      email: string,
      firstName: string,
      middleName: string,
      lastName: string,
      streetName1: string,
      streetName2: string,
      postalCode: string,
      phone: string,
      city: string,
      state: string,
      regionExchange: string,
    },
    sweepId: string,
  },
): Promise<any> => {
  const res = await fetchApiHub('/action/sweepstakes/submitSweepstakes', { method: 'POST' }, payload);
  return res;
};

export const confirmDeleteAccount = async (data): Promise<any> => {
  const res = await fetchApiHub('/action/account/confirmDeleteAccount', { method: 'POST' }, data);
  return res;
};

export const addSearchTerm = async (searchTerm: string) => {
  await fetchApiHub(`/action/account/addSearchTerm`, { method: 'POST' }, { searchTerm });
};
export const getSearchTerms = async () => {
  return await fetchApiHub(`/action/account/getSearchTerms`, { method: 'GET' });
};
export const clearSearchTerms = async () => {
  return await fetchApiHub(`/action/account/clearSearchTerms`, { method: 'GET' });
};
export const vetVerify = async (data): Promise<any> => {
  const payload = {
    vetVerifyResquest: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      dob: data?.dobYear + data?.dobMonth + data?.dobDay,
      ssn: data?.ssn,
      serviceNbr: data?.serviceNbr,
      email: data?.email,
      bos: data?.serviceCode,
    },
  };
  const res = await fetchApiHub('/action/vetVerify/vetVerify', { method: 'POST' }, payload);
  return res;
};
export const dD214Upload = async (data): Promise<any> => {
  const payload = {
    dD214Upload: {
      doD_EDINumber: data?.vetVerifyRes?.dodEdiNumber,
      fileExtension: data?.fileExtension,
      registrationId: data?.vetVerifyRes?.registrationId,
      document: data?.uploadPdfData,
    },
  };
  const res = await fetchApiHub('/action/vetVerify/dD214Upload', { method: 'POST' }, payload);
  return res;
};
export const getMaoChatUrl = async (): Promise<any> => {
  const res = await fetchApiHub('/action/account/getMaoChatUrl', { method: 'GET' });
  return res;
};

export const getCustomerPreferenceBraze = async (): Promise<any> => {
  const res = await fetchApiHub('/action/braze/getCustomerPreferenceBraze', { method: 'GET' });
  return res;
};
